import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"; // Import zodResolver
import { onSnapshot, query } from "firebase/firestore";
import {
  db,
  collection,
  addDoc,
  getDocs,
  where,
} from "@/utility/firebase_options";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useTamuStore from "@/stores/tamuStore";
import { toast } from "react-toastify";
const index = () => {
  const { tamu } = useTamuStore();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    ucapan: z.string().min(1, "Silahkan tuliskan ucapan terlebih dahulu"), // Ucapan wajib diisi
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: tamu.displayName,
      ucapan: "",
    },
    resolver: zodResolver(schema),
  }); // Initialize useForm

  useEffect(() => {
    setValue("displayName", tamu.displayName);
  }, [tamu.displayName, setValue]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (tamu.id === null) {
        toast.error(
          "Mohon maaf, Anda tidak dapat mengisi ucapan saat ini. Nama Anda belum terdaftar dalam daftar tamu kami."
        );
        return;
      }
      setLoading(true);
      const docRef = collection(db, "ucapan");
      const q = query(docRef, where("tamuId", "==", tamu.id));
      const querySnapshot = await getDocs(q);
      setLoading(false);

      const totalUcapan = querySnapshot.size;

      if (totalUcapan >= 3) {
        toast.error("Maaf, Anda hanya dapat mengirim hingga 3 ucapan.");

        return;
      }
      addDoc(docRef, {
        tamuId: tamu.id,
        displayName: tamu.displayName,
        ucapan: data.ucapan,
        createdAt: new Date().toISOString(),
      })
        .then(() => {
          toast.success("Ucapan berhasil dikirim.", { autoClose: 1000 });
          reset();
        })
        .catch((_) => {
          toast.error("Gagal mengirim ucapan.", { autoClose: 1000 });
        });
    },
    [tamu, reset, setLoading]
  );
  useEffect(() => {
    // {{ edit_4 }}
    const unsubscribe = onSnapshot(collection(db, "ucapan"), (snapshot) => {
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ); // Sort by newest
      setComments(data as any); // Simpan data ke state
    });

    return () => unsubscribe(); // Bersihkan listener saat komponen di-unmount
  }, []);
  const commentsPerPage = 5; // Jumlah komentar per halaman
  const [currentPage, setCurrentPage] = useState(0); // Halaman saat ini
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentComments = useMemo(() => {
    return comments.slice(0, (currentPage + 1) * commentsPerPage);
  }, [comments, currentPage, commentsPerPage]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef, comments.length]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;

      if (
        scrollTop + clientHeight >= scrollHeight &&
        (currentPage + 1) * commentsPerPage < comments.length
      ) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef, comments.length, commentsPerPage, currentPage]);

  return (
    <section className="wedding-wish-wrap" data-template="">
      <div className="wedding-wish-inner">
        <div className="wedding-wish-head">
          <h1
            className="wedding-wish-title"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Ucapan &amp; Harapan
          </h1>
        </div>

        <div className="wedding-wish-body">
          <div className="wedding-wish-form">
            <form
              id="weddingWishForm"
              className="wi-flex wi-flex-col wi-h-full wi-gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              {" "}
              {/* Add onSubmit handler */}
              <div
                className="form-group guest-name-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <p className="!wi-text-secondary">{tamu.displayName}</p>
              </div>
              <div
                className="form-group guest-comment-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <textarea
                  disabled={loading}
                  id="ucapan"
                  {...register("ucapan")} // Register textarea with react-hook-form
                  className="form-control !wi-rounded-md guest-comment wi-w-full focus:wi-outline-none"
                  rows={3}
                  placeholder="Berikan ucapan"
                ></textarea>
                {errors.ucapan && (
                  <p className=" !wi-text-sm !wi-text-red-500 wi-mt-1">
                    {errors.ucapan.message}
                  </p>
                )}
              </div>
              <div
                className="submit-comment-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400"
              >
                <button
                  disabled={loading}
                  type="submit"
                  className="submit submit-comment"
                  data-last=""
                >
                  Kirim
                </button>
              </div>
            </form>
          </div>
          {comments.length > 0 && (
            <div
              className="wi-flex wi-flex-col wi-overflow-y-scroll wi-h-[calc(400px)] wi-px-4"
              ref={containerRef}
            >
              {currentComments.map((comment: any, index: number) => (
                <div
                  key={index}
                  className="comment-item aos-init aos-animate"
                  id={`comment${index}`}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <div className="comment-head">
                    <h3 className="comment-name">
                      {comment.displayName} <i className="fas fa-check"></i>
                    </h3>
                    <small className="comment-date">
                      {new Date(comment.createdAt).toLocaleDateString()}
                    </small>
                  </div>
                  <div className="comment-body">
                    <p className="comment-caption">{comment.ucapan}</p>
                  </div>
                </div>
              ))}
              {/* <Pagination
              className="wi-mt-4"
              align="center"
              defaultCurrent={1}
              total={comments.length}
              pageSize={commentsPerPage}
              hideOnSinglePage
              onChange={(page) => setCurrentPage(page - 1)}
            /> */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default index;
