import TamuModel from "@/types/TamuModel";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const LihatUndanganModal = ({
  isModalLihatUndanganOpen,
  setIsModalLihatUndanganOpen,
  tamu,
  copyInvitationLink,
}: {
  isModalLihatUndanganOpen: boolean;
  setIsModalLihatUndanganOpen: (value: boolean) => void;
  tamu: TamuModel;
  copyInvitationLink: (tamu: TamuModel) => void;
}) => {
  const handleOkLihatUndangan = () => {
    setIsModalLihatUndanganOpen(false);
  };

  const handleCancelLihatUndangan = () => {
    setIsModalLihatUndanganOpen(false);
  };

  return (
    <Modal
      title={
        <div className="wi-flex wi-items-center">
          <p className="!wi-text-white !wi-text-[1.5rem]">Lihat Undangan</p>
          <button onClick={() => copyInvitationLink(tamu)} className="wi-ml-2">
            <CopyOutlined className="wi-text-white" />
          </button>
        </div>
      }
      open={isModalLihatUndanganOpen}
      onOk={handleOkLihatUndangan}
      onCancel={handleCancelLihatUndangan}
      footer={null}
      width={600}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      {isModalLihatUndanganOpen && (
        <div className="wi-text-white">
          <p>
            Kepada Yth, Bapak/Ibu/Saudara/i.
            <br />
            <br />
            <strong>{tamu.displayName}</strong>
            <br />
            <br />
            Dengan segala hormat, kami mengundang Bapak/Ibu/Saudara/i untuk
            menghadiri acara pernikahan kami:
            <br />
            <br />
            <strong>Adhitya Rachman Hidayatullah dan Rizka Agustin</strong>
            <br />
            <br />
            Berikut adalah link undangan kami yang berisi informasi lengkap
            mengenai acara tersebut:
            <br />
            <br />
            <a
              href={`${window.location.origin}/?to=${tamu.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="wi-text-blue-500"
            >
              {`${window.location.origin}/?to=${tamu.id}`}
            </a>
            <br />
            <br />
            Lokasi acara:
            <br />
            Oryza BULOG
            <br />
            Jl. Gatot Subroto No.Kav 49, RT.5/RW.4, Kuningan Tim., Kecamatan
            Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12950
            <br />
            Kabupaten Jakarta Selatan
            <br />
            <br />
            Link peta:
            <br />
            <a
              href="https://maps.app.goo.gl/rkQBTAaC7zAHK5kYA"
              target="_blank"
              rel="noopener noreferrer"
              className="wi-text-blue-500"
            >
              https://maps.app.goo.gl/rkQBTAaC7zAHK5kYA
            </a>
            <br />
            <br />
            Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
            Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu pada
            acara pernikahan kami.
            <br />
            <br />
            Atas perhatian dan kehadirannya, kami ucapkan terima kasih yang
            sebesar-besarnya.
          </p>
        </div>
      )}
    </Modal>
  );
};

export default LihatUndanganModal;
